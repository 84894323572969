import { Spacing } from '@/components/theme/styles/rawSpacing'
import { Inter } from '@/components/theme/styles/typography/Fonts'
import {
  Button as MUIButton,
  SxProps,
  Theme,
  ButtonProps as MuiButtonProps,
} from '@mui/material'
import { ComponentColors } from '@/components/theme/styles/colors'
import { PropsWithChildren, ReactNode, Ref } from 'react'
import { BoxShadows } from '@/components/theme/styles/shadow'
import { RingShadows } from '@/components/theme/styles/ringShadow'

export type MuiColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'quaternary'
  | 'link'

export type ButtonProps = Omit<
  MuiButtonProps,
  'size' | 'variant' | 'color' | 'type'
> & {
  /** `color` is based on the MUI naming convention. */
  color?: MuiColor
  /**
   * `variant` is an imitation of MUI's style to suit our own design.
   * We are calculating the MUI variant based on color.
   */
  variant?: 'brand' | 'grey' | undefined
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  type?: 'standard' | 'destructive' | undefined
  startIcon?: JSX.Element | undefined
  endIcon?: JSX.Element | undefined
  children?: ReactNode | undefined
  sx?: SxProps<Theme>
  disabled?: boolean | undefined
  Icon?: React.ElementType | undefined
  isActive?: boolean
  /**
   * Added to avoid using `React.forwardRef` for {@link ./ControlledDatePicker.tsx}.
   *
   * @see {@link https://stackoverflow.com/a/65756885 How do I avoid 'Function components cannot be given refs' when using react-router-dom?}
   */
  muiRef?: Ref<HTMLButtonElement>
}

const getBackgroundColor = ({
  type,
  color,
  variant,
}: Pick<ButtonProps, 'type' | 'color' | 'disabled' | 'variant'>) => {
  if (color === 'primary') {
    return type === 'destructive'
      ? ComponentColors.bg['error-solid']
      : ComponentColors.bg['brand-solid']
  } else if (color === 'secondary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.bg.primary
        : ComponentColors.bg.primary
    } else if (variant === 'brand') {
      // @Todo Anuka 22/11/2023: No design yet
    }
  } else if (color === 'tertiary') {
    if (variant === 'grey') {
      return 'transparent'
    } else if (variant === 'brand') {
      // @Todo Anuka 22/11/2023: No design yet
    }
  } else if (color === 'link') {
    return 'transparent'
  }
}

const getHoverBackgroundColours = ({
  type,
  color,
}: Pick<ButtonProps, 'type' | 'color'>) => {
  if (color === 'primary') {
    return type === 'destructive'
      ? ComponentColors.bg['error-hover']
      : ComponentColors.bg['brand-hover']
  } else if (color === 'secondary') {
    return type === 'destructive'
      ? ComponentColors.bg['error-primary']
      : ComponentColors.bg['primary-hover']
  } else if (color === 'tertiary') {
    return type === 'destructive'
      ? // @todo (Liam-MyHR, 2023-11-23) Destructive variant for secondary button is not designed yet.
        ComponentColors.bg['error-primary']
      : ComponentColors.bg['primary-hover']
  } else if (color === 'quaternary') {
    return type === 'destructive'
      ? // @todo (Liam-MyHR, 2023-11-23) Destructive variant for secondary button is not designed yet.
        ComponentColors.bg['error-primary']
      : ComponentColors.bg['primary-hover']
  } else if (color === 'link') {
    return 'transparent'
  }
}

const getDisabledBackgroundColours = ({
  color,
}: Pick<ButtonProps, 'type' | 'color'>) => {
  switch (color) {
    case 'primary':
      return ComponentColors.bg.disabled
    case 'secondary':
      return ComponentColors.bg.primary
    case 'tertiary':
      return 'transparent'
    case 'quaternary':
      return 'transparent'
    case 'link':
    default:
      return 'transparent'
  }
}

const getForegroundColors = ({
  type,
  color,
  variant,
}: Pick<ButtonProps, 'type' | 'color' | 'variant'>) => {
  if (color === 'primary') {
    return ComponentColors.fg.inverse
  } else if (color === 'secondary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.secondary
    } else if (variant === 'brand') {
      // @Todo Anuka 22/11/2023: No design yet
    }
  } else if (color === 'tertiary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.tertiary
    } else if (variant === 'brand') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.tertiary
    }
  } else if (color === 'quaternary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.quaternary
    } else if (variant === 'brand') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.quaternary
    }
  } else if (color === 'link') {
    if (variant === 'grey') {
      return ComponentColors.text.tertiary
    } else {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg.brand
    }
  }
  return 'inherit'
}

const getHoverForegroundColors = ({
  type,
  color,
  variant,
}: Pick<ButtonProps, 'type' | 'color' | 'variant'>) => {
  if (color === 'primary') {
    return ComponentColors.fg.inverse
  } else if (color === 'secondary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? ComponentColors.fg['error-primary']
        : ComponentColors.fg['secondary-hover']
    } else if (variant === 'brand') {
      // @Todo Anuka 22/11/2023: No design yet
    }
  } else if (color === 'tertiary') {
    // Destructive buttons don't have variants, as of 2023-11-23.
    if (type === 'destructive') {
      return ComponentColors.fg['error-hover']
    }
    if (variant === 'grey') {
      return ComponentColors.fg['tertiary-hover']
    } else if (variant === 'brand') {
      // @todo (myhr-chman, 2023-11-23) No design yet.
    }
  } else if (color === 'quaternary') {
    // Destructive buttons don't have variants, as of 2023-11-23.
    if (type === 'destructive') {
      return ComponentColors.fg['error-hover']
    }
    if (variant === 'grey') {
      return ComponentColors.fg['quarternary-hover']
    } else if (variant === 'brand') {
      // @todo (myhr-chman, 2023-11-23) No design yet.
    }
  } else if (color === 'link') {
    if (variant === 'grey') {
      return ComponentColors.text['tertiary-hover']
    } else {
      return type === 'destructive'
        ? ComponentColors.fg['error-hover']
        : ComponentColors.fg['brand-hover']
    }
  }
  return 'inherit'
}

const getFocusBoxShadow = ({
  color,
  type,
}: Pick<ButtonProps, 'type' | 'color'>) => {
  // Destructive buttons don't have variants, as of 2023-11-23.
  if (type === 'destructive') {
    return RingShadows['error-shadow-xs']
  }
  if (color === 'primary') {
    return RingShadows['brand-shadow-xs']
  }
  if (color === 'secondary') {
    return RingShadows['brand-shadow-xs']
  }
  if (color === 'tertiary') {
    return RingShadows['gray-shadow-xs']
  }
  if (color === 'quaternary') {
    return 'none'
  }
  if (color === 'link') {
    return 'none'
  }
}
const getBoxShadow = ({ color }: Pick<ButtonProps, 'color'>) => {
  if (color === 'primary') {
    return BoxShadows.xs
  }
  if (color === 'secondary') {
    return BoxShadows.xs
  }
  if (color === 'tertiary') {
    return 'none'
  }
  if (color === 'quaternary') {
    return 'none'
  }
  if (color === 'link') {
    return 'none'
  }
}

const getBorderColor = ({
  type,
  color,
  variant,
}: Pick<ButtonProps, 'type' | 'color' | 'variant'>) => {
  if (color === 'primary') {
    return 'none'
  }
  if (color === 'secondary') {
    if (variant === 'grey') {
      return type === 'destructive'
        ? `1px solid ${ComponentColors.border['error']}`
        : `1px solid ${ComponentColors.border.primary}`
    }
    return 'none'
  }
  if (color === 'tertiary') {
    return 'none'
  }
  if (color === 'quaternary') {
    return 'none'
  }
  if (color === 'link') {
    return 'none'
  }
}
const getDisabledBorderColor = ({
  color,
}: Pick<ButtonProps, 'type' | 'color' | 'variant'>) => {
  if (color === 'secondary') {
    return `1px solid ${ComponentColors.border['disabled-subtle']}`
  }
  return 'none'
}

export const Button = ({
  color = 'primary',
  variant = 'grey',
  type = 'standard',
  size = 'md',
  isActive = false,
  disabled,
  startIcon,
  endIcon,
  sx = {},
  children,
  Icon,
  muiRef,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  const muiVariant =
    color === 'primary'
      ? 'contained'
      : color === 'secondary' ||
        color === 'tertiary' ||
        color === 'quaternary' ||
        color === 'link'
      ? 'text'
      : 'outlined' // Clear mistake happened somewhere here.

  const fontSize =
    size === 'sm' || size === 'md'
      ? Inter['text sm']['medium']
      : size === 'lg' || size === 'xl'
      ? Inter['text md']['medium']
      : size === '2xl'
      ? Inter['text lg']['medium']
      : 'inherit'

  const paddingX =
    size === 'sm'
      ? 12
      : size === 'md'
      ? 14
      : size === 'lg'
      ? 16
      : size === 'xl'
      ? 18
      : 22

  const paddingY =
    size === 'sm'
      ? 8
      : size === 'md'
      ? 10
      : size === 'lg'
      ? 10
      : size === 'xl'
      ? 12
      : 14

  const contentHeight =
    size === 'sm'
      ? 20
      : size === 'md'
      ? 20
      : size === 'lg'
      ? 24
      : size === 'xl'
      ? 24
      : 28

  const iconPadding =
    size === 'sm'
      ? 8
      : size === 'md'
      ? 8
      : size === 'lg'
      ? 10
      : size === 'xl'
      ? 10
      : 14

  const iconSize =
    size === 'sm'
      ? 16
      : size === 'md'
      ? 16
      : size === 'lg'
      ? 16
      : size === 'xl'
      ? 16
      : 19

  const customSx = {
    fontSize: fontSize,
    boxShadow: isActive
      ? getFocusBoxShadow({ type, color })
      : getBoxShadow({ color }),
    border: getBorderColor({ type, color, variant }),
    borderRadius: Icon ? 2 : Spacing.spacing[100],
    paddingX: `${paddingX}px`,
    paddingY: `${paddingY}px`,
    backgroundColor: getBackgroundColor({ type, color, variant }),
    color: getForegroundColors({ type, color, variant }),
    '&': {
      textTransform: 'none',
    },
    '&:hover': {
      backgroundColor: getHoverBackgroundColours({ type, color }),
      color: getHoverForegroundColors({ type, color, variant }),
      boxShadow: isActive ? getFocusBoxShadow({ type, color }) : 'none',
    },
    '&:disabled': {
      backgroundColor: getDisabledBackgroundColours({ color }),
      color: ComponentColors.fg.disabled,
      border: getDisabledBorderColor({ color }),
    },
    // This shows when the button is focused by keyboard.
    '&:focus-visible': {
      boxShadow: getFocusBoxShadow({ type, color }),
    },
    '&:focus:not(:hover)': {
      backgroundColor:
        getBackgroundColor({ type, color, variant }) ?? 'transparent',
      color: getForegroundColors({ type, color, variant }),
    },
    height: `${contentHeight + 2 * paddingY}px`,
    minHeight: `${contentHeight + 2 * paddingY}px`,
    maxHeight: `${contentHeight + 2 * paddingY}px`,
    '.MuiButton-startIcon': {
      paddingRight: iconPadding + 'px',
      marginRight: '0px',
      marginLeft: '2px',
      svg: {
        width: iconSize + 'px',
        height: iconSize + 'px',
      },
    },
    '.MuiButton-endIcon': {
      paddingLeft: iconPadding + 'px',
      marginRight: '2px',
      marginLeft: '0px',
      svg: {
        width: iconSize + 'px',
        height: iconSize + 'px',
      },
    },
    minWidth: 'max-content',
    ...sx, // Todo: sx can be an array. Need to handle that.
  }

  // TS does not like MUIButton sx prop if `textTransform` is set above. Weird!
  customSx['textTransform'] = 'none'

  if (Icon) {
    const buttonSize =
      size === 'sm'
        ? '36px'
        : size === 'md'
        ? '40px'
        : size === 'lg'
        ? '44px'
        : size === 'xl'
        ? '48px'
        : size === '2xl'
        ? '56px'
        : '1000px' // Too big and will break the button

    customSx['width'] = buttonSize
    customSx['height'] = buttonSize
    customSx['minWidth'] = buttonSize
    customSx['minHeight'] = buttonSize
    customSx['maxWidth'] = buttonSize
    customSx['maxHeight'] = buttonSize

    const iconOnlySize =
      size === 'sm'
        ? '16px'
        : size === 'md'
        ? '16px'
        : size === 'lg'
        ? '16px'
        : size === 'xl'
        ? '16px'
        : size === '2xl'
        ? '19px'
        : '1000px' //Too big and will break the button

    children = <Icon style={{ width: iconOnlySize, height: iconOnlySize }} />
  }

  return (
    <MUIButton
      startIcon={startIcon}
      endIcon={endIcon}
      variant={muiVariant}
      disabled={disabled}
      disableFocusRipple
      disableRipple
      sx={customSx}
      {...rest}
      ref={muiRef}
    >
      {children}
    </MUIButton>
  )
}
