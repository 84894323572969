import { GlobalStyles } from '@mui/material'

/** Inter font plus fallbacks to reduce "flash of unstyled content" effect. */
export const interFont =
  "Inter, 'Helvetica Neue', Helvetica, Helvetica, Arial, sans-serif"

export const GlobalStyle = () => (
  <GlobalStyles
    styles={{
      '*': {
        fontFamily: interFont,
      },
    }}
  />
)
