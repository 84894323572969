import { NextPageContext } from 'next'
import App, { AppContext } from 'next/app'
import { UserPing } from '@/auth/components/UserPing'
import { Provider } from '@/components/contexts/Provider'
import { get } from '@/utils/api'
import { determineLocale } from '@/utils/determineLocale'
import { getUserRoleProps, userPermissions } from '@/utils/userPermissions'
import { isRedirection, RedirectError } from '@/utils/RedirectError'
import { AskUsWidget } from '@/ask-us/AskUsWidget'
import { GTM } from '@/components/GTM'
import Head from 'next/head'
import { MyHRThemeProvider } from '@/components/contexts/MyHRThemeProvider'

// Not sure why this is required only on some pages?
// See https://stackoverflow.com/a/73187593/481207
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false

// Import global styles in _app.tsx
// See https://nextjs.org/docs/basic-features/built-in-css-support#adding-a-global-stylesheet
import '@/styles/foundation-grid.css'
import '@/styles/main.css'
import '@/styles/journal-font.css'
import '@/styles/base-font.css'
import '@/styles/base-font-customisations.css'
import '@/styles/inter.css'
import {
  isLoginButNotSecondStepPath,
  isSecondStepLoginPath,
  isMfaRequired,
  isMultiMemberSelectRequired,
  getNextUrl,
  isAnonymousPath,
  isStartsWithLoginPath,
  isSwitchAccountPath,
  isSSOLoginPath,
  isSignUpPath,
  isSelfServicePath,
} from '../auth/loginFlowHelpers'
import { GlobalNoAuthStyle } from '../components/noAuthComponents/GlobalNoAuthStyle'
import { Fragment } from 'react'
import ono from '@jsdevtools/ono'
import { AxiosError } from 'axios'
import createEmotionCache from '@/createEmotionCache'
import { EmotionCache, CacheProvider } from '@emotion/react'
import { User } from '@/types/User'
import { APIResponseError } from '@/types/APIResponse'
import { PageProps, Person, AppNextPageContext } from '@/types/multiple'
import { MyHR } from '@/types/MyHR'
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum'
import { isErrorComponent } from '@/utils/isErrorComponent'
import { ServerErrorPage } from '@/components/noAuthComponents/ServerErrorPage'
import { ServerError } from '@/types/ServerError'
import { billingRedirection } from '@/utils/billingRedirection'
import { getOnboardingStatus } from '@/components/onboarding/utils/getOnboardingStatus'
import { sanitizeRedirectUrl } from '@/components/noAuthComponents/Login'
import { GlobalStyle } from '@/components/GlobalStyle'

type InitialProps = {
  pageProps: PageProps | undefined
  error?: ServerError
}

if (process.env.DATADOG_RUM === 'enabled') {
  datadogRum.init({
    applicationId: process.env.DATADOG_RUM_APPLICATION_ID || '',
    clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN || '',
    site: 'datadoghq.com',
    service: 'member-forms',
    env: process.env.DATADOG_RUM_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    startSessionReplayRecordingManually: true,
    defaultPrivacyLevel: (process.env.DATADOG_RUM_PRIVACY_LEVEL ||
      'mask') as DefaultPrivacyLevel,
  })

  datadogRum.startSessionReplayRecording()
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

type Props = {
  pageProps: PageProps | undefined
  emotionCache?: EmotionCache
  error?: ServerError
}
class MyApp extends App<Props> {
  render(): JSX.Element {
    const {
      Component,
      pageProps,
      router: { asPath },
      emotionCache = clientSideEmotionCache,
    } = this.props

    if (
      isAnonymousPath(asPath) ||
      isStartsWithLoginPath(asPath) ||
      isSwitchAccountPath(asPath) ||
      isSignUpPath(asPath)
    ) {
      // console.log('_app.noAuthPages: emotionCache: ', emotionCache)
      // I expect /login to come this path
      return (
        <Fragment>
          <GlobalNoAuthStyle />
          <GlobalStyle />
          <MyHRThemeProvider layout="basic">
            <Head>
              {/* Use minimum-scale=1 to enable GPU rasterization */}
              <meta
                name="viewport"
                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
              />
            </Head>
            <GTM>
              <Component {...pageProps} />
            </GTM>
          </MyHRThemeProvider>
        </Fragment>
      )
    }

    if (isErrorComponent(Component)) {
      return <Component {...pageProps} />
    }

    if (!pageProps) {
      const { error } = this.props
      if (error) {
        return <ServerErrorPage error={error} />
      }
      throw new Error(
        'pageProps unexpectedly undefined perhaps an api call failed in a Component.getInitialProps'
      )
    }
    const typedPageProps: PageProps = pageProps
    const locale = determineLocale(typedPageProps)

    const { myhr } = typedPageProps
    const { user } = myhr
    return (
      <CacheProvider value={emotionCache}>
        <Provider locale={locale} pageProps={typedPageProps}>
          <GlobalStyle />
          <Head>
            {/* Use minimum-scale=1 to enable GPU rasterization */}
            <meta
              name="viewport"
              content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
            />
          </Head>
          <GTM pageProps={typedPageProps}>
            <UserPing initialUser={user} />
            <Component {...pageProps} />
            <AskUsWidget />
          </GTM>
        </Provider>
      </CacheProvider>
    )
  }

  static async getUser(ctx: NextPageContext): Promise<MyHR | null> {
    const { res } = ctx
    try {
      const resp = await get<MyHR>({ ctx, url: `/api/user/auth` })
      if (res && resp.headers['set-cookie']) {
        res.setHeader('set-cookie', resp.headers['set-cookie'])
      }
      const { data: response } = resp
      return {
        ...response,
        user: {
          ...response.user,
          ...getUserRoleProps(response),
        },
        userPermissions: userPermissions(response),
      }
    } catch (err) {
      const typedError = err as unknown as AxiosError<APIResponseError>
      const { status } = err.response || {}
      if (status === 403 || (status === 422 && !err.response?.data?.url)) {
        return null
      }
      if (console && status !== 422) {
        console.log('_app.tsx.getUser: error: ', typedError.message)
        console.log('_app.tsx.getUser: error: ', typedError.response?.data)
      }

      throw err
    }
  }

  private static currentSection(path: string) {
    let currentSection = 'launchpad'
    if (path) {
      const parts = path.split('/')
      if (parts.length >= 1) {
        currentSection = parts[1]
      }
      // Management user menu items so launchpad
      if (
        ['manage-account', 'manage-integrations', 'my-account'].includes(
          currentSection
        )
      ) {
        currentSection = 'launchpad'
      }
    }
    return currentSection
  }

  static async getPerson(
    appCtx: AppContext,
    member_id: number
  ): Promise<Person | undefined> {
    const {
      router: { query },
      ctx,
    } = appCtx
    if (query.personId) {
      const url = `/api/member/${member_id}/person/${query.personId}`
      const { data: person } = await get<Person>({ url, ctx })
      return person
    }
  }

  /**
   * @param appCtx
   * @returns The URL that should be redirected to after login.
   */
  private static getLoginRedirectUrl(appCtx: AppContext): string {
    const url = sanitizeRedirectUrl(appCtx.router.query)

    // console.log('getLoginRedirectUrl', appCtx.router.query.url, url)

    // A URL of '/' results in an infinite loop. I can't figure out why.
    // redirect.ts appears to redirect to '/', but in getInitialProps, the
    // path is still '/login'?

    return url === '/' ? '/launchpad' : url
  }

  /**
   * Gets the self-service URL that a person should be redirected to if the
   * user does not have access to management.
   * **Note: all person-related urls are sub-paths of `/self-service`**
   *
   * @param appCtx
   * @param myhr
   * @returns The URL that should be redirected to if user is a person and
   * the current URL is not self service.
   */
  private static getRedirectToSelfService(
    appCtx: AppContext,
    myhr: MyHR
  ): string | undefined {
    const {
      router: { asPath: path },
    } = appCtx
    const url = isLoginButNotSecondStepPath(path)
      ? this.getLoginRedirectUrl(appCtx)
      : path
    const { user } = myhr

    if (
      user?.person_id &&
      user.role === 'person' &&
      !url.startsWith('/self-service')
    ) {
      return '/self-service'
    }
  }

  /**
   * Gets redirect after logging in.
   *
   * @param appCtx
   * @param myhr
   * @returns The URL that should be redirected to when login succeeds.
   */
  static getRedirectUrlForUser(appCtx: AppContext, myhr: MyHR): string {
    return (
      this.getRedirectToSelfService(appCtx, myhr) ||
      this.getLoginRedirectUrl(appCtx)
    )
  }

  static async getAnonymousProps(appCtx: AppContext, user?: User) {
    let pageProps: PageProps = {} as PageProps

    if (appCtx.Component.getInitialProps) {
      pageProps = (await appCtx.Component.getInitialProps(
        appCtx.ctx
      )) as PageProps
    }

    if (user) {
      pageProps.user = user
    }

    return {
      pageProps,
    }
  }

  static async getPropsForLoggedInUser(
    appCtx: AppContext,
    myhr: MyHR
  ): Promise<InitialProps> {
    const {
      router: { asPath: path },
      Component,
      ctx,
    } = appCtx
    const isSelfService = path.includes('self-service')

    let person: Person | undefined

    try {
      person = await this.getPerson(appCtx, myhr.member.id)
    } catch (err) {
      const status = err?.response?.status
      if (status === 403) {
        return {
          pageProps: undefined,
          error: {
            title: 'Access Denied',
            message:
              "You do not have manager permission to view this person's employment record. This may require a department change, please see your manager to action.",
            status,
            url: err?.request?.path,
          },
        }
      }
      throw err
    }

    // Get person being managed or self-service person.
    const loggedInPerson = person ?? myhr.person

    const { isOnboarding } = getOnboardingStatus(
      loggedInPerson,
      myhr,
      isSelfService ? 'SelfService' : 'ManagerEditing'
    )

    let pageProps: PageProps = {
      myhr,
      currentSection: this.currentSection(path),
      person,
      isOnboarding,
      cacheBustVersionString: 'version-20220919-1501',
    }

    // if component has own getInitialProps -> call it
    if (Component.getInitialProps) {
      const appPageCtx: AppNextPageContext = {
        ...ctx,
        pageProps,
      }
      pageProps = {
        ...pageProps,
        ...(await Component.getInitialProps(appPageCtx)),
      }
    }

    return { pageProps }
  }

  static async getInitialProps(appCtx: AppContext): Promise<InitialProps> {
    const {
      Component,
      ctx,
      router: { asPath: path, query },
    } = appCtx
    let myhr
    const queryUrl = query.url as string | undefined

    if (isErrorComponent(Component)) {
      // DONT getInitialProps if this is an error page because doing so will probably cause an error!!!!
      return { pageProps: undefined }
    }

    // redirection try/catch outer blocks
    try {
      console.log('Step 1', path, query.url)

      try {
        myhr = await this.getUser(ctx)
      } catch (err) {
        const typedError = err as unknown as AxiosError<APIResponseError>
        if (![401, 403, 422].includes(typedError.response?.status || 0)) {
          if (console) {
            console.warn(
              '_app.tsx.getInitialProps: Unexpected error getting myhr: ',
              typedError.message
            )
          }
        }

        // console.log('Step 2')

        // try {
        if (isAnonymousPath(path)) {
          return await this.getAnonymousProps(appCtx, myhr?.user)
        } // if MFA

        // console.log('Step 3')

        if (isMfaRequired(err)) {
          if (!ctx.asPath?.startsWith('/login/mfa')) {
            const nextUrl = getNextUrl(path, queryUrl, '/login/mfa')
            throw new RedirectError('isMfaRequired').setRedirection({
              ctx,
              nextUrl,
            })
          }
          const res = await this.getAnonymousProps(appCtx, myhr?.user)
          return {
            pageProps: {
              ...res.pageProps,
              mfaAction: err.response.data,
            },
          }
        }

        // console.log('Step 4')

        // if multiple accounts
        if (isMultiMemberSelectRequired(err)) {
          if (!ctx.asPath?.startsWith('/login/multiple-account')) {
            const nextUrl = getNextUrl(
              path,
              queryUrl,
              '/login/multiple-account'
            )
            throw new RedirectError(
              'isMultiMemberSelectRequired'
            ).setRedirection({
              ctx,
              nextUrl,
            })
          }
          return await this.getAnonymousProps(appCtx, myhr?.user)
        }

        throw ono(err, 'Unexpected error from _app.tsx getUser')
      }

      // console.log('Step 5')

      if (myhr?.user && myhr.user?.role === 'admin' && !myhr?.user?.member_id) {
        if (
          !ctx.asPath?.startsWith('/login/admin') &&
          !isAnonymousPath(ctx.asPath || '')
        ) {
          // Always send role='admin' to '/launchpad' to avoid 404 errors when masquerading as different members
          const nextUrl = getNextUrl(path, '/launchpad', '/login/admin')
          throw new RedirectError('isAdminSelectMemberRequired').setRedirection(
            {
              ctx,
              nextUrl,
            }
          )
        }
        return await this.getAnonymousProps(appCtx, myhr?.user)
      }

      const user = myhr?.user

      // console.log('Step 6')

      // The user is fully logged on to MyHR and needs to continue
      // the SAML logon
      if (
        user &&
        ((isSSOLoginPath(queryUrl) && !isSecondStepLoginPath(path)) ||
          isSSOLoginPath(path))
      ) {
        if (queryUrl && isSSOLoginPath(queryUrl)) {
          // We've finished with 2FA and/or multi-account selection
          // and need to return to /login/sso to continue the SAML flow
          throw new RedirectError('isSSOLogin').setRedirection({
            ctx,
            nextUrl: queryUrl,
          })
        } else {
          // On the /login/sso page already which needs to know
          // that it can continue the SAML flow (because the user is logged on)
          return await this.getAnonymousProps(appCtx, user)
        }
      }

      // console.log('Step 7')

      // if user has access to management and self-service and
      // doesn't have preselected path
      if (
        user &&
        user.person_id &&
        ['manager', 'owner'].includes(user.role) &&
        !query.url &&
        !isAnonymousPath(path)
      ) {
        // if path empty or it's a login path
        if (
          (isLoginButNotSecondStepPath(path) &&
            !path.startsWith(`/login/departmental`)) ||
          !path ||
          path === '/'
        ) {
          throw new RedirectError(
            'isManagementOrSelfServiceSelectionRequired'
          ).setRedirection({
            ctx,
            nextUrl: `/login/departmental`,
          })
        }

        if (path === '/login/departmental') {
          // todo: investigate why we're returning user here??
          return { pageProps: { user } } as unknown as InitialProps
        }
      }

      // console.log('Step 8')

      // user can be navigated to second step pages through 422 status in fetching mfa object
      if (isSecondStepLoginPath(path)) {
        const nextUrl = getNextUrl(path, queryUrl, '/login')
        throw new RedirectError('isSecondStepLoginPath').setRedirection({
          ctx,
          nextUrl,
        })
      }

      const isAuthenticated = !!myhr?.user

      // console.log('Step 9')

      if (isAnonymousPath(path)) {
        return await this.getAnonymousProps(appCtx, myhr?.user)
      }

      /**
       * Redirection for billing by throwing a RedirectError if needed
       */
      billingRedirection(path, myhr, ctx)

      // console.log('Step 10', path)
      // console.log('isSignUpPath(path)', isSignUpPath(path))

      if (!isLoginButNotSecondStepPath(path) && !isSignUpPath(path)) {
        if (isAuthenticated) {
          const url = this.getRedirectToSelfService(appCtx, myhr)

          if (url) {
            // console.log('Redirect to', url)

            throw new RedirectError(
              'isAuthenticatedRedirectToNextUrl'
            ).setRedirection({
              ctx,
              nextUrl: url,
            })
          }

          // Don't allow admins & managers/owners without self service to access self service pages
          if (!myhr.user?.person_id && isSelfServicePath(path)) {
            throw new RedirectError(
              'isAuthenticatedRedirectToNextUrl'
            ).setRedirection({
              ctx,
              nextUrl: '/launchpad',
            })
          }

          // console.log('User is logged in')

          // isAuthentificated means that myhr is not null
          return await this.getPropsForLoggedInUser(appCtx, myhr)
        } else {
          const redirectUrl = isAnonymousPath(path)
            ? '/login'
            : `/login?url=${path}`

          // console.log('Redirect to login', redirectUrl)

          throw new RedirectError(
            'isNotLoginPathRedirectToLoginPage'
          ).setRedirection({
            ctx,
            nextUrl: redirectUrl,
          })
        }
      } else {
        // This is a no-auth page.

        if (isAuthenticated && !isSignUpPath(path)) {
          // isAuthentificated means that myhr is not null
          const url = this.getRedirectUrlForUser(appCtx, myhr)

          // console.log('Redirect to no-auth', url)

          throw new RedirectError(
            'isAuthenticatedRedirectToNextUrl'
          ).setRedirection({
            ctx,
            nextUrl: url,
          })
        } else {
          // console.log('User is not logged in')
          return await this.getAnonymousProps(appCtx, myhr?.user)
        }
      }
    } catch (err) {
      if (isRedirection(err)) {
        // console.log('Redirect in catch', err.nextUrl)
        err.doRedirect()
      } else {
        console.log('Error occurred in getInitialProps', err)
      }
      //NB: DONT try and send the err object back as it contains recursive structures that can't be serialised
      return {
        pageProps: undefined,
        error: {
          title: 'Error Occurred',
          message:
            'An unexpected error occurred while trying to view this page.  Please try again.',
          status: err?.response?.status,
          url: err?.request?.path,
        },
      }
    }
  }
}

export default MyApp
